var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "action-collapse": "",
      "title": "Daftar Ajuan Tukar Poin"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('Per page'),
      "label-size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "options": _vm.pageOptions,
      "size": "sm"
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('Filter'),
      "label-size": "sm"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "size": "sm",
      "type": "search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter,
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Reward",
      "label-size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "selectedjenis",
      "options": _vm.jenisOptions,
      "size": "sm"
    },
    on: {
      "input": function ($event) {
        return _vm.getDataPoin();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedjenis,
      callback: function ($$v) {
        _vm.selectedjenis = $$v;
      },
      expression: "selectedjenis"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fieldsFinance,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(urutan)",
      fn: function (_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(reward)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.item.reward.judul) + " "), _c('img', {
          staticClass: "d-block mb-1",
          staticStyle: {
            "width": "70px"
          },
          attrs: {
            "src": row.item.reward.patch ? row.item.reward.patch : '-',
            "alt": ""
          }
        })])];
      }
    }, {
      key: "cell(poin)",
      fn: function (row) {
        return [[_vm._v(" " + _vm._s(_vm.formatRupiah(row.value)) + " ")]];
      }
    }, {
      key: "cell(cek)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.status == 'pending' || item.status == 'proses' ? _c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.cek,
            callback: function ($$v) {
              _vm.cek = $$v;
            },
            expression: "cek"
          }
        }) : _vm._e()];
      }
    }, {
      key: "cell(diberikan)",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.iberi[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.iberi[0][data.value]) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.statuswarna[1][item.status]
          }
        }, [_vm._v(" " + _vm._s(_vm.statuswarna[0][item.status]) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.typewarna[1][item.type]
          }
        }, [_vm._v(" " + _vm._s(_vm.typewarna[0][item.type]) + " ")])];
      }
    }, {
      key: "cell(nominal)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.nominal)) + " ")];
      }
    }, {
      key: "cell(tanggal)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal) + ' ' + item.waktu) + " ")];
      }
    }, {
      key: "cell(diterima)",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.iterima[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.iterima[0][data.value]) + " ")])];
      }
    }, {
      key: "cell(status2)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.getInfo(item).variant
          }
        }, [_vm._v(" " + _vm._s(_vm.getInfo(item).status) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm.allowUpdate() && item.status == 0 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Setujui Ajuan Tukar Poin',
            expression: "'Setujui Ajuan Tukar Poin'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-50",
          attrs: {
            "size": "sm",
            "variant": "outline-success"
          },
          on: {
            "click": function ($event) {
              return _vm.setuju(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "CheckCircleIcon"
          }
        })], 1) : _vm._e(), _vm.allowUpdate() && item.status == 0 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Batalkan Ajuan Tukar Poin',
            expression: "'Batalkan Ajuan Tukar Poin'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-50",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.batalkan(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XCircleIcon"
          }
        })], 1) : _vm._e(), _vm.allowUpdate() && item.status == 2 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus Ajuan Tukar Poin',
            expression: "'Hapus Ajuan Tukar Poin'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-50",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.hapus(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "Trash2Icon"
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }